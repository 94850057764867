import React,  {useState, useEffect, useCallback, FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import SecureRoute from 'SecureRoute';
import TokenService from 'services/tokenService';
import Role from 'components/Role';
import { getGuestUser, getAllCamera } from './services/apiConfig';
import { SUPERSET_URL, SUPERSET_URL_DASHBOARD_1, SUPERSET_URL_DASHBOARD_2, SUPERSET_URL_DASHBOARD_3 } from './constants';


import menu from './pages/menu.json';
import styled from 'styled-components';
import { MainMenu, Layout, TopBar, Spinner, Label, usePoll } from 'scorer-ui-kit';
import { IMenuItemSubmenu, IMenuItemTop, IMenuTop } from 'scorer-ui-kit/dist/Global';
import LogoTextAppNameEn from './svg/logo-app-name_en.svg';
import logoMarkSvg from './svg/logo-mark.svg';
import PasswordModal from 'components/PasswordModal';


import Login from 'pages/Login';
import AddCamera from 'pages/AddCamera';
import SafieIntegration from 'pages/SafieIntegration';
import CloudUploadSettings from 'pages/CloudUploadSettings';
import CameraDetails from 'pages/CameraDetails';
import Export from 'pages/Export';
import EmailAlert from 'pages/EmailAlert';
import Cameras from 'pages/Cameras';
import CameraConfiguration from 'pages/CameraConfiguration';
import UserManagement from 'pages/User';
import CreateEditUser from 'pages/User/CreateEditUser';
import axios from 'axios';
import { EDGE_API_BASE_URL } from './constants';

import clonedeep from 'lodash.clonedeep';
// import DiskFullNotification from './components/DiskFullNotification';

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  user-select: none;
`;

const Wrapper = styled.div`
  max-height: calc(100vh - 65px);
  overflow-y: auto;
`;

const MainMenuContainer = styled(MainMenu)`
  user-select: none;
`;

const SpinnerBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;


const App: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const userName = TokenService.getUser();
  const { t } = useTranslation(['CommonDict']);
  // const [isDiskFull, setIsDiskFull] = useState<boolean>(false);

  const [menus, setMenus] = useState<IMenuTop>({ items: [] });
  const [userType, setUserType] = useState(false);
  const [guestUser, setGuestUser] = useState<number>(0);
  const [loadingGuest, setLoadingGuest] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState(!(TokenService?.getUser()) ? false : true);
  const [boundingBoxes, setBoundingBoxes] = useState(typeof(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string) === 'string' ? JSON.parse(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string) : true );

  const [deviceId, setDeviceId] = useState<string>('');
  const [deviceTags, setDeviceTags] = useState<string>('');
  const fetchDeviceDetails = useCallback(async () => {
    const res = await axios.get(`${EDGE_API_BASE_URL}system/all`);
    setDeviceId(res.headers.device_id);
    const tagNames = (res.data as any).data.stacks.display.services.left_menu.tags.map((tag: any) => tag.name);
    setDeviceTags(tagNames.join(',　'));
  }, []);
  useEffect(()=>{
    fetchDeviceDetails();
  }, [fetchDeviceDetails]);


  useEffect(() => {
    if (!isAuthenticated 
      && !location.pathname.startsWith('/cameras/camera-details/')
      && location.pathname !== '/login'
      && location.pathname !== '/cameras'
      && location.pathname !== '/export'
    ) {
      history.replace('/login');
    }
    if (localStorage.getItem(`${window.location.hostname}_isMenuOpen`) === null) {
      localStorage.setItem(`${window.location.hostname}_isMenuOpen`, 'true');
    }
  }, [isAuthenticated, location, history]);


  const fetchGuestData = useCallback(async () => {
    setLoadingGuest(true);
    try {
      const { data: { data, status_code } } = await getGuestUser();
      if (status_code === 200) {
        setGuestUser(data.guest_enabled);
      } else {
        setGuestUser(0);
      }
    } catch (error) {
      setGuestUser(0);
      console.error((error as Error).message);
    } finally {
      setLoadingGuest(false);
    }
    setBoundingBoxes(typeof(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string) === 'string' ? JSON.parse(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string) : true );
  }, [userName]);
  
  useEffect(() => {
    fetchGuestData();
  }, [fetchGuestData]);


  const translateMenus = useCallback(() => {
    const menuData = [
      ...menu.items,
      { 
        'icon': 'Metabase',
        'title': 'Visualization',
        isExternalLink: true,
        'href': SUPERSET_URL ,
        submenu:[
          {
            'title': 'Hourly (Today)',
            'href': SUPERSET_URL_DASHBOARD_1,
            isExternalLink: true
          },
          {
            'title': 'Daily (Last 30 days)',
            'href': SUPERSET_URL_DASHBOARD_2,
            isExternalLink: true
          },
          {
            'title': 'Monthly (Last 3 months)',
            'href': SUPERSET_URL_DASHBOARD_3,
            isExternalLink: true
          }
        ]
      }
    ];

    const data = menuData.map((item: IMenuItemTop) => {
      if (item.submenu && item.submenu.length > 0) {
        const submenu = item.submenu.map((submenu: IMenuItemSubmenu) => {
          return { ...submenu, 'title': t(submenu.title) };
        });
        return { ...item, 'title': t(item.title), 'submenu': submenu };
      } else {
        return { ...item, 'title': t(item.title) };
      }
    });

    if (!TokenService.getUserType()) {        
      const guestMenuData = clonedeep(data);
      guestMenuData.splice(guestMenuData.findIndex(a => a.href === '/emailAlert'), 1);
      return { items: [...guestMenuData] };
    } else {
      return { items: [...data] };
    }
  }, [t]);

  useEffect(() => {
    setUserType(TokenService.getUserType());  
    setMenus(translateMenus());
  }, [translateMenus]);


  const loginGuest = useCallback(() => {
    const data = {
      access_token: 'guest',
      refresh_token: 'guest',
      user: {
        role: 'guest',
        user_id: 0,
        username: 'Guest'
      }
    };

    sessionStorage.setItem('isGuestLogout', 'false');
    TokenService.setUser(data);
    setIsAuthenticated(true);
  }, []);

  const accessGuest = useCallback(() => {
    if(location.pathname !== '/cameras'
      && location.pathname !== '/export'
      && !location.pathname.startsWith('/cameras/camera-details/')
    ){
      return;
    }
    loginGuest();
  }, [location, loginGuest]);

  useEffect(() => {
    if (!loadingGuest) {
      if (guestUser) {
        if (sessionStorage.getItem('isGuestLogout') && sessionStorage.getItem('isGuestLogout') === 'true') {
          setIsAuthenticated(false);
          TokenService.removeUser();
          return;
        }
        if (!localStorage.getItem('user_login_info')){
          accessGuest();
        } else {
          setIsAuthenticated(!(TokenService?.getUser()) ? false : true);
        }
      } else {
        setIsAuthenticated(!(TokenService?.getUser()) ? false : true);
      }
    }
    if(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) === null){
      localStorage.setItem(`checkoBox_Video_Annotation_${userName}`, 'true');
    }
  }, [loadingGuest, guestUser, accessGuest, userName]);


  const onLanguageChange = useCallback(() => {
    const language = i18n.language === 'ja' ? 'en' : 'ja';
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }, []);


  // fetchAllCamera & put indexedDB
  const dbName = 'static_image_analysis';
  const storeName = 'cameraData';
  useEffect(() => {
    const request = indexedDB.open(dbName, 1);
    request.onerror = (e) => console.error('indexedDB error:', (e.target as IDBOpenDBRequest).error);
    request.onupgradeneeded = (e) => {
      const db = (e.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: 'id' });
      }
    };
  }, []);
  const fetchAllCamera = useCallback(async () => {
    try {
      const { data: { status_code, data } } = await getAllCamera();
      if (status_code === 200) {
        const request = indexedDB.open(dbName, 1);
        request.onsuccess = (e) => {
          const db = (e.target as IDBOpenDBRequest).result;
          const transaction = db.transaction(storeName, 'readwrite');
          const store = transaction.objectStore(storeName);
          store.put({'id': 'allCameraData', 'data': data});
        };
      }
    } catch (error) {
      console.error((error as Error).message);
    }
  }, []);
  usePoll(async () => {
    await fetchAllCamera();
  }, (1000 * 60));



  const getApp = () => {
    return (
      <>
        <MainMenuContainer
          content={menus}
          logoMark={logoMarkSvg}
          logoText={LogoTextAppNameEn}
          keepOpenText={t('Keep Open')}
          autoHideText={t('Auto-Hide')}
          defaultMenuOpen={false}
          canAlwaysPin
        />
        <MainContainer>
          {/* {isDiskFull && <DiskFullNotification type='error' message={t('Disk capacity is almost full. Please perform clean-up.')} />} */}
          <TopBar
            accountOptionText={t('Account Options')}
            loggedInUser={TokenService.getUser()}
            logoutText={t('Logout')}
            currentUserText={t('CURRENT USER')}
            onLanguageToggle={onLanguageChange}
            hasLanguage
            hasLogout={false}
            userDrawerBespoke={<PasswordModal {...{boundingBoxes, setBoundingBoxes}} />}
            hasUserDrawerMeta
            userDrawerMeta={[
              {
                icon: 'Information',
                title: i18n.language === 'ja' ? 'デバイスID' : 'Device ID',
                subTitle: deviceId ? deviceId : '-',
                notes:'',
                hasCopyIcon:true
              },
              {
                icon: 'Information',
                title: i18n.language === 'ja' ? 'デバイスタグ' : 'Device tags',
                subTitle: deviceTags ? deviceTags : '-',
                notes:'',
                hasCopyIcon:true
              },
            ]}
            //hasUserDrawerFooter
            userDrawerFooter={{icon: 'Information', title: '-'}}
            copySuccessMessage={t('Copied!')}
            // selectedLanguageText={language}
          />
          <Role />

          <Wrapper>
            <SecureRoute path='/cameras/camera-details/:streamName/:tab' exact component={CameraDetails} />
            {userType &&
              <>
                <SecureRoute path='/cameras/camera-configuration/:streamName' exact component={CameraConfiguration} />
                <SecureRoute path='/add-camera' exact component={AddCamera} />
                <SecureRoute path='/edit-camera' exact component={AddCamera} />
                <SecureRoute path='/settings/create-user' exact component={CreateEditUser} />
                <SecureRoute path='/settings/edit-user' exact component={CreateEditUser} />
                <SecureRoute path='/settings/cloud-settings' exact component={CloudUploadSettings} />
                <SecureRoute path='/settings/safie-settings' exact component={SafieIntegration} />
                <SecureRoute path='/settings/user-management' exact component={UserManagement} />
                <SecureRoute path='/emailAlert' exact component={EmailAlert} />
              </>}
            <SecureRoute exact path={['/', '/login']}>
              <Redirect to='/cameras' />
            </SecureRoute>
            <SecureRoute path='/cameras' exact component={Cameras} />
            <SecureRoute path='/export' exact component={Export} />
          </Wrapper>
        </MainContainer>
      </>
    );
  };

  const getLoginRoute = () => {
    return (
      <>
        <Route render={() => <Login {...{guestUser, loginGuest}} />} />
      </>
    );
  };

  return (
    <Layout>
      {!loadingGuest ?
        !isAuthenticated ? getLoginRoute() : getApp()
        :
        <SpinnerBox>
          <Spinner size='large' styling='primary' />
          <Label htmlFor='loader' labelText={t('Loading') + '...'} />
        </SpinnerBox>}
    </Layout>
  );
};

export default App;
